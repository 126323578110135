<section *ngIf="filterOption" class="w-100" id="basic-alerts">
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card card-statistics" [ngClass]="externalClass">
                <div class="card-header">
                    <h4 class="card-title">{{ toSentenceCase(filterOption.title) }}</h4>
                    <div class="d-flex">
                        <div
                            *ngFor="let btn of filterOption.filterButtons"
                            class="btn-group mr-2 ml-2"
                        >
                            <ng-select
                                [placeholder]="btn.placeholder"
                                [bindLabel]="btn.label || ''"
                                [bindValue]="btn.value || ''"
                                [items]="btn.options"
                                class="ng-input"
                                [(ngModel)]="selectedValues[btn.name]"
                                (change)="changeUpdate($event, btn.name)"
                                [clearable]="clearable === false ? clearable : btn.clearable"
                            >
                            </ng-select>
                        </div>
                        <div *ngIf="filterOption.template">
                            <ng-container [ngTemplateOutlet]="filterOption.template"></ng-container>
                        </div>
                    </div>
                </div>
                <hr style="margin: 0 !important" />
                <div class="card-body" style="padding: 20px 16px">
                    <main-app-analytics-infor-card
                        [analyticsList]="filterOption?.analyticsList"
                        [analyticsInfo]="filterOption.analyticsData"
                    ></main-app-analytics-infor-card>
                </div>
            </div>
        </div>
    </div>
</section>
