export const enums = {
    countries: ['Nigeria', 'Ivory Coast'],
    COUNTRY_LIST: {
        IVORY_COAST: 'Ivory Coast',
        NIGERIA: 'Nigeria',
    },
    countriesCurrency: {
        'Nigeria': {
            name: 'Nigeria',
            currency: 'NGN',
            currencyDisplay: 'symbol-narrow',
        },
        'Ivory Coast': {
            currency: 'XOF',
            name: 'Ivory Coast',
            currencyDisplay: 'symbol-narrow',
        },
    },
    schemeTypes: [
        {
            value: 'gross',
            label: 'Gross',
        },
        {
            value: 'basic',
            label: 'Basic',
        },
        {
            value: 'hourly',
            label: 'Hourly',
        },
        {
            value: 'daily',
            label: 'Daily',
        },
    ],
    SALARY_SCHEME: {
        CIV: {
            ITEMS: [
                {
                    name: 'Basic Salary',
                    label: 'Basic',
                    value: 0,
                    payrollBudgetId: null,
                    isOptional: false,
                    type: ['gross'],
                    pensionEnabled: true,
                    taxEnabled: true,
                    schedule: 'monthly',
                    derivative: false,
                    selected: true,
                    isDefault: true,
                    meta: {
                        useBiAnnually: false,
                        useQuarterly: false,
                        useAnnually: false,
                        useMonthly: false,
                        checkable: false,
                        hidePension: true,
                        hideTax: true,
                    },
                },
                {
                    name: 'Transport',
                    label: 'Basic',
                    value: 0,
                    payrollBudgetId: null,
                    isOptional: false,
                    type: ['gross', 'basic'],
                    pensionEnabled: true,
                    taxEnabled: true,
                    schedule: 'monthly',
                    derivative: false,
                    selected: true,
                    isDefault: true,
                    meta: {
                        useBiAnnually: false,
                        useQuarterly: false,
                        useAnnually: false,
                        useMonthly: false,
                        checkable: false,
                        hidePension: true,
                        hideTax: true,
                    },
                },
            ],
        },
        HEALTH_INSURANCES: {
            NHF: 2.5,
            NHIS: 5,
            NSITF: 1,
        },
        DEFAULT_ITEMS_KEY: {
            BASIC: 'Basic',
            HOUSING: 'Housing',
            TRANSPORT: 'Transport',
            LEAVE: 'Leave',
            MEDICAL: 'Medical',
        },
        ITEMS: [
            {
                name: 'Basic',
                label: 'Basic',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    useMonthly: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
            {
                name: 'Transport',
                label: 'Transport',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Housing',
                label: 'Housing',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Feeding',
                label: 'Feeding',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Leave',
                label: 'Leave',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                pensionEnabled: false,
                taxEnabled: true,
                schedule: 'annually',
                derivative: false,
                selected: false,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
            {
                name: 'Medical',
                label: 'Medical',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
                pensionEnabled: false,
                taxEnabled: true,
                derivative: false,
                selected: false,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
        ],
        DEFAULT_NON_MONTHLY: {
            name: '',
            label: 'OtherAllowance',
            value: 0,
            payrollBudgetId: null,
            isOptional: true,
            type: ['basic', 'gross'],
            schedule: 'annually',
            pensionEnabled: false,
            taxEnabled: true,
            derivative: false,
            selected: false,
            isDefault: false,
            meta: {
                useMonthly: false,
                hidePension: true,
                hideTax: true,
            },
        },
        DEFAULT_MONTHLY: {
            name: '',
            label: '',
            value: 0,
            payrollBudgetId: null,
            isOptional: true,
            type: ['basic', 'gross'],
            schedule: 'annually',
            pensionEnabled: false,
            taxEnabled: true,
            derivative: false,
            selected: false,
            isDefault: false,
            meta: {
                useBiAnnually: false,
                useQuarterly: false,
                useAnnually: false,
                hidePension: true,
                hideTax: true,
                useMonthly: false,
            },
        },
        ITEMSOLD: [
            {
                name: 'Basic',
                label: 'Basic',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    useMonthly: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
            {
                name: 'Transport',
                label: 'Transport',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Housing',
                label: 'Housing',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Feeding',
                label: 'Feeding',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'monthly',
                derivative: false,
                selected: true,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    useAnnually: false,
                    checkable: false,
                    hidePension: true,
                    hideTax: true,
                    useMonthly: false,
                },
            },
            {
                name: 'Leave',
                label: 'Leave',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                pensionEnabled: true,
                taxEnabled: true,
                schedule: 'annually',
                derivative: false,
                selected: false,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
            {
                name: 'Medical',
                label: 'Medical',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
                pensionEnabled: true,
                taxEnabled: true,
                derivative: false,
                selected: false,
                isDefault: true,
                meta: {
                    useBiAnnually: false,
                    useQuarterly: false,
                    hidePension: true,
                    hideTax: true,
                },
            },
            {
                name: '',
                label: 'OtherAllowance',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
                pensionEnabled: true,
                taxEnabled: true,
                derivative: false,
                selected: false,
                isDefault: false,
                meta: {
                    useMonthly: false,
                },
            },
        ],
        PENSION_TYPES: [
            {
                title: '10% : 8% Contribution',
                employer: 10,
                employee: 8,
            },
            {
                title: "20% Employer's Contribution",
                employer: 20,
                employee: 0,
            },
        ],
    },
    YEARS: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    MONTHS: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    DAYS: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    PAYROLL_SPLIT_SECTIONS: [
        {
            number: 1,
            batchTitle: 'Batch A',
            percentageTitle: 'First Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 2,
            batchTitle: 'Batch B',
            percentageTitle: 'Second Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 3,
            batchTitle: 'Batch C',
            percentageTitle: 'Third Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 4,
            batchTitle: 'Batch D',
            percentageTitle: 'Fourth Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
    ],
    PAYROLL_WORKFLOW: ['CREATE_PAYROLL', 'REVIEW_PAYROLL', 'APPROVE_PAYROLL'],
    PAYROLL: {
        STATUS: {
            ACTIVE: 1,
            REVIEWED: 2,
            APPROVED: 3,
            REJECTED: 4,
        },
        STATUS_: {
            1: 'Active',
            2: 'Reviewed',
            3: 'Approved',
            4: 'Rejected',
        },
        PAYMENT_STATUS: {
            NOT_PAID: 0,
            PAID: 1,
            PROCESSED: 2,
        },
        PAYMENT_STATUS_: {
            0: 'Not Paid',
            1: 'Paid',
            2: 'Processed',
        },
        WORKFLOW: ['CREATE_PAYROLL', 'REVIEW_PAYROLL', 'APPROVE_PAYROLL'],
        WORKFLOW_: {
            CREATE_PAYROLL: 'CREATE PAYROLL',
            REVIEW_PAYROLL: 'REVIEW PAYROLL',
            APPROVE_PAYROLL: 'APPROVE PAYROLL/PAYMENT',
        },
    },
    PAYROL_COLUMN: [
        { value: 'Branch' },
        // { value: 'Department' }
    ],
    WALLET: {
        STATUS: {
            0: 'Debit',
            1: 'Credit',
        },
    },
};

export enum PayrollHistoryTypesEnums {
    'payroll' = 'payroll',
    'health' = 'health',
    'tax' = 'tax',
    'pension' = 'pension',
    'cash_advance' = 'cash-advance',
    'cash_reimbursement' = 'cash-reimbursement',
    'leave_allowance' = 'leave-allowance',
    'allowance' = 'allowance',
    'tax_pension' = 'tax-and-pension',
    '13th_month' = '13th-month',
    'non_payroll' = 'non-payroll',
}

export const PayrollTypeConfig: any = {
    [PayrollHistoryTypesEnums.payroll]: {
        name: 'Payroll',
        view: '/payment/payroll/payroll-history/view-payroll/',
        edit: '/payment/payroll/payroll-history/edit-payroll/',
        url: ['payment', 'payroll', 'payroll-history'],
        payrollUrl: ['payment', 'payroll', 'payroll-history', 'view-payroll'],
        crumb: [
            {
                name: 'Payroll History',
                isLink: true,
                link: '/payment/payroll/payroll-history',
            },
        ],
    },
    [PayrollHistoryTypesEnums['13th_month']]: {
        name: '13th Month Payroll',
        view: '/payment/payroll/13th-month/view-13th-month/',
        edit: '/payment/payroll/13th-month/edit-13th-month/',
        url: ['payment', 'payroll', '13th-month'],
        payrollUrl: ['payment', 'payroll', '13th-month', 'view-13th-month'],
        crumb: [
            {
                name: '13th Month',
                isLink: true,
                link: '/payment/payroll/13th-month',
            },
        ],
    },
    [PayrollHistoryTypesEnums.non_payroll]: {
        name: 'Non Payroll',
        view: '/payment/payroll/non-payroll/view-non-payroll/',
        edit: '/payment/payroll/non-payroll/edit-non-payroll/',
        url: ['payment', 'payroll', 'non-payroll'],
        payrollUrl: ['payment', 'payroll', 'non-payroll', 'view-non-payroll'],
        crumb: [
            {
                name: 'Non Payroll',
                isLink: true,
                link: '/payment/payroll/non-payroll',
            },
        ],
    },
    [PayrollHistoryTypesEnums.leave_allowance]: {
        name: 'Leave Allowance',
        view: '/payment/allowance/leave/view-leave/',
        edit: '/payment/allowance/leave/edit-leave/',
        url: ['payment', 'allowance', 'leave'],
        payrollUrl: ['payment', 'allowance', 'leave', 'view-leave'],
        crumb: [
            {
                name: 'Leave',
                isLink: true,
                link: '/payment/allowance/leave',
            },
        ],
    },
    [PayrollHistoryTypesEnums.health]: {
        name: {
            'Nigeria': 'Medical Allowance',
            'Ivory Coast': 'CMU Contribution',
        },
        view: '/payment/allowance/medical/view-medical/',
        edit: '/payment/allowance/medical/edit-medical/',
        url: ['payment', 'allowance', 'medical'],
        payrollUrl: ['payment', 'allowance', 'medical', 'view-medical'],
        crumb: (props: any) => {
            const item: any = {
                'Nigeria': 'Medical',
                'Ivory Coast': 'C.M.U',
            };
            return [
                {
                    name: item[props.country],
                    isLink: true,
                    link: `/payment/allowance/medical/${props.country}`,
                },
            ];
        },
    },
    [PayrollHistoryTypesEnums.allowance]: {
        name: 'Other Allowance',
        view: '/payment/allowance/other-allowance/view-other-allowance/',
        edit: '/payment/allowance/other-allowance/edit-other-allowance/',
        url: ['payment', 'allowance', 'other-allowance'],
        payrollUrl: ['payment', 'allowance', 'other-allowance', 'view-other-allowance'],
        crumb: [
            {
                name: 'Other Allowance',
                isLink: true,
                link: '/payment/allowance/other-allowance',
            },
        ],
    },
    [PayrollHistoryTypesEnums.tax]: {
        name: 'Tax Remittance',
        view: '/payment/remittance/tax/view-tax/',
        edit: '/payment/remittance/tax/edit-tax/',
        url: ['payment', 'remittance', 'tax'],
        payrollUrl: ['payment', 'remittance', 'tax', 'view-tax'],
        crumb: [
            {
                name: 'Tax',
                isLink: true,
                link: '/payment/remittance/tax',
            },
        ],
    },
    [PayrollHistoryTypesEnums.pension]: {
        name: 'Pension Remittance',
        view: '/payment/remittance/pension/view-pension/',
        edit: '/payment/remittance/pension/edit-pension/',
        url: ['payment', 'remittance', 'pension'],
        payrollUrl: ['payment', 'remittance', 'pension', 'view-pension'],
        crumb: [
            {
                name: 'Pension',
                isLink: true,
                link: '/payment/remittance/pension',
            },
        ],
    },
};
