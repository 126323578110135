const appConstants = {
    APPROVALTYPES: {
        ADMIN_ROLES: 'admin roles',
        CADRES: 'cadre',
    },
    ROLES: {
        OWNER: 'owner',
        HRMANAGER: 'hrmanager',
        HROFFICER: 'hrofficer',
        ITOFFICER: 'itofficer',
        MANAGER: 'manager',
        MANGERSMANAGER: 'managersmanager',
    },
    MM: {
        id: 'managersmanager',
        name: 'mangers manager',
        type: 'managersmanager',
    },
    defaultRoles: [
        {
            id: 'manager',
            roleName: 'manager',
            type: 'manager',
        },
        {
            id: 'specific_person',
            roleName: 'specific person',
            person: 'person',
        },
    ],
    defaultPageOptions: [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ],
    defaultPeriodOptions: [
        {
            key: 'All',
            value: '',
            selected: true,
        },
        {
            key: 'Last 7 Days',
            value: '7',
        },
        {
            key: 'Last 14 days',
            value: '14',
        },
        {
            key: 'Last 30 days',
            value: '30',
        },
    ],
    loaderOptions: {
        path: './assets/motions/loader.json',
    },
    features: {
        dashboard: 'dashboard',
        events: 'events',
        employee: 'employee',
        allEmployee: 'allEmployee',
        department: 'department',
        branch: 'branch',
        businessUnit: 'businessUnit',
        division: 'division',
        inviteEmployee: 'inviteEmployee',
        setupTeams: 'setupTeams',
        bonuses: 'bonuses',
        deductions: 'deductions',
        taxReliefs: 'taxReliefs',
        benefits: 'benefits',
        cadre: 'cadre',
        confirmation: 'confirmation',
        confirmationPolicy: 'confirmationPolicy',
        confirmationEvaluation: 'confirmationEvaluation',
        enps: 'enps',
        enpsDashboard: 'enps',
        enpsSurvey: 'enpsSurvey',
        safetyAndIncident: 'safetyAndIncident',
        safetyAndIncidentReports: 'safetyAndIncidentReports',
        payments: 'payments',
        payroll: 'payroll',
        allowances: 'allowances',
        remittances: 'remittances',
        grievance: 'grievance',
        grievancePolicy: 'grievancePolicy',
        grievanceReport: 'grievanceReport',
        grievanceQuery: 'grievanceQuery',
        grievanceCase: 'grievanceCase',
        attendance: 'attendance',
        attendanceList: 'attendanceList',
        attendanceSetup: 'attendanceSetup',
        attendanceDeviceList: 'attendanceDeviceList',
        exits: 'exits',
        exitPolicy: 'exitPolicy',
        exitList: 'exitList',
        exitRequests: 'exitRequests',
        exitSettings: 'exitSettings',
        leave: 'leave',
        leaveApplication: 'leaveApplication',
        leaveSetup: 'leaveSetup',
        promotions: 'promotions',
        promotionPolicy: 'promotionPolicy',
        promotionSettings: 'promotionSettings',
        promotionRequests: 'promotionRequests',
        promotionReport: 'promotionReport',
        timeTracker: 'timeTracker',
        pendingApproval: 'pendingApproval',
        recruitment: 'recruitment',
        jobOpportunities: 'jobOpportunities',
        applicationForm: 'applicationForm',
        jobRequests: 'jobRequests',
        talentPool: 'talentPool',
        interviews: 'interviews',
        onboarding: 'onboarding',
        preEmploymentTest: 'preEmploymentTest',
        recruitmentSettings: 'recruitmentSettings',
        requisition: 'requisition',
        meetings: 'meetings',
        health: 'health',
        healthPay: 'healthPay',
        healthProviders: 'healthProviders',
        purchaseHistory: 'purchaseHistory',
        performance: 'performance',
        chats: 'chats',
        chatSettings: 'chatSettings',
        jobRole: 'jobRoles',
        learning: 'learning',
        learningTrainings: 'learningTrainings',
        learningRequests: 'learningRequests',
        learningFeedback: 'learningFeedback',
        learningCourses: 'learningCourses',
        learningBudget: 'learningBudget',
        learningROI: 'learningROI',
        learningVendors: 'learningVendors',
        eLearning: 'eLearning',
        eLearningUpload: 'eLearningUpload',
        eLearningCourses: 'eLearningCourses',
        eLearningPaths: 'eLearningPaths',
        eLearningStudyReports: 'eLearningStudyReports',
        eLearningRecommendation: 'eLearningRecommendation',
        eLearningPublished: 'eLearningPublished',
        eLearningUnPublished: 'eLearningUnPublished',
        eLearningAttempts: 'eLearningAttempts',
        talentManagement: 'talentManagement',
        careerDevelopment: 'careerDevelopment',
        successionPlanning: 'successionPlanning',
        talentReviews: 'talentReviews',
        reports: 'reports',
        performanceReports: 'performanceReports',
        leaveReports: 'leaveReport',
        recruitmentReport: 'recruitmentReport',
        payrollReport: 'payrollReport',
        employeeReport: 'employeeReport',
        compensationReport: 'compensationReport',
        cultureKPI: 'cultureKPI',
        employmentKPI: 'employmentKPI',
        dms: 'dms',
        organogram: 'organogram',
        organogramDepartment: 'organogramDepartment',
        approvalWorkflow: 'approvalWorkflow',
        license: 'license',
        users: 'users',
        rolesAndPermission: 'rolesAndPermission',
        payrollSettings: 'payrollSettings',
        wallet: 'wallet',
        integration: 'integration',
        documentSettings: 'documentSettings',
        activityLogs: 'activityLogs',
        settings: 'settings',
        generateAIJobSummary: 'generateAIJobSummary',

        // TRACKER
        tracker: 'tracker',
        tracker_Dashboard: 'tracker_Dashboard',
        tracker_userDashboard: 'tracker_userDashboard',
        tracker_realTime: 'tracker_realTime',
        tracker_reports: 'tracker_reports',
        tracker_timeline: 'tracker_timeline',
        tracker_captures: 'tracker_captures',
        tracker_editTime: 'tracker_editTime',
        tracker_payroll: 'tracker_payroll',
        tracker_settings: 'tracker_settings',

        // PROJECT
        project: 'project',
        project_dashboard: 'project_dashboard',
        project_list: 'project_list',
        promotionCommittee: 'promotionCommittee',

        oldPayrollDashboard: 'oldPayrollDashboard',
        payrollDashboard: 'payrollDashboard',
        createVirtualWallet: 'createVirtualWallet',

        //NOTCHHR Rewind
        notchhr_rewind: 'notchhr_rewind',
        globalization: 'globalization',
    },
};

export default appConstants;
