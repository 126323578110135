import { Component, OnInit, Input } from '@angular/core';

// Breadcrumb component interface
export interface Breadcrumb {
    type?: string;
    alignment?: string;
    useTitleCase?: boolean;
    links?: Array<{
        name: string;
        isLink: boolean;
        link?: string;
        query?: any;
    }>;
}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    @Input() breadcrumb!: any;

    constructor() {}

    ngOnInit() {
        this.breadcrumb.useTitleCase = this.breadcrumb.useTitleCase ?? true;
    }
}
